import React, { useCallback, useEffect, useState } from 'react';

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => {
  const [isMount, setMount] = useState(false);
  
  useEffect(() => {
        setMount(true);
  },[])

  if(!isMount) {
     return(
        <div>Loading...</div>
     )
  }
  return <Layout pageInfo={{ pageName: "404" }}>
    <Seo title="404: Not found" keywords={[`crypto`, `Fi Bot`, `defi`, `Fi Bot`, `cryptoFi Bot`]} />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
}

export default NotFoundPage
